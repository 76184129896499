<template lang="pug">
  include ../plugins/pug/btn

  section.section.banner__section
    div.container
      div.hbanner
        div.dialog__window
          div.dialog__window-top
            span
          div.dialog__window-content
            div.typing__text
              span(
                v-for="(message, index) in messages"
                :key="index"
                :class="{ 'typewriter': true, 'active': activeIndex === index }"
                :style="{ '--n': message.length }"
                ) {{ message }}
            div(:class="{ 'get__started': isLastTypewriterActive }").text-center
              +btn-icon('Next', 'space__icon.svg')(v-if="!isLastTypewriterActive" @click="nextStep")
              +btn-icon('Get Started', 'play__icon.svg')(v-if="isLastTypewriterActive" v-scroll-to="'#services__block'")

        div.hbanner__social
          SocialBlock

        TechnologiesBlock
</template>

<script>
export default {
  name: 'HomeBanner',
  components: {
    SocialBlock: () => import('@/components/SocialBlock'),
    TechnologiesBlock: () => import('@/components/TechnologiesBlock')
  },
  data () {
    return {
      messages: [
        'Welcome to Disoft - where innovative ideas meet exceptional solutions',
        'Discover the power of technology with our company - your trusted IT development partner',
        'Let\'s begin our journey of acquaintance together'
      ],
      activeIndex: 0
    }
  },
  computed: {
    isLastTypewriterActive () {
      return this.activeIndex === this.messages.length - 1
    }
  },
  methods: {
    nextStep () {
      if (this.activeIndex < this.messages.length - 1) this.activeIndex += 1
    }
  }
}
</script>
